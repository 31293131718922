/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.react_color {
  width: 100% !important;
  height: 127px !important;
}
// .react-colorful__saturation {
//   display: none !important;
// }
.react-colorful__last-control {
  border-radius: 8px 8px 8px 8px !important;
}
.custom-hover-effect {
  color: #000000 !important;
  font-weight: 500 !important; /* Bootstrap's gray color */
}
.text_muted {
  color: #787878 !important;
  font-weight: 500 !important; /* Bootstrap's gray color */
}
.custom-hover-effect:hover {
  color: #6c757d !important; /* Bootstrap's gray color */
}
.assign_icon {
  border: 2px dotted;
  border-radius: 50%;
  padding: 5px;
}
.dropdown-menu-index {
  z-index: 1000000;
  position: absolute;
}
.visually-hidden-input {
  position: absolute;
  right: 30px;
  width: 0px;
  height: 0px;
  z-index: -111;
  overflow: hidden;
  background: unset;
  border: none;
}
.titles {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.input-group-dob {
  width: 96%;
  border: 1px solid #d8d6de;
  padding: 8px 10px;
  border-radius: 0px 5px 5px 0p;
}
.input-group-dob:focus-visible {
  outline: none !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
  border: 1px solid rgba(34, 41, 47, 0.1) !important;
}
.input-group-dob:focus-within {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
  border-radius: 0.357rem !important;
}
.updated_at {
  color: rgb(0 0 0) !important;
}

.list-item.un_read {
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}

.list-item.un_read::before {
  content: ''; /* Required for the pseudo-element to show */
  position: absolute;
  left: 0;
  top: 50%; /* Adjusts the border to be vertically centered */
  transform: translateY(-50%); /* Ensures the centering is accurate */
  height: 70%; /* Adjust the height as needed */
  width: 4px; /* The width of your border */
  background-color: blue; /* The color of your border */
}
.list-item.readed {
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}

.list-item.readed::before {
  content: ''; /* Required for the pseudo-element to show */
  position: absolute;
  left: 0;
  top: 50%; /* Adjusts the border to be vertically centered */
  transform: translateY(-50%); /* Ensures the centering is accurate */
  height: 70%; /* Adjust the height as needed */
  width: 4px; /* The width of your border */
  background-color: rgb(21, 230, 28); /* The color of your border */
}
.dead_title {
  font-size: 14px;
  font-weight: 900;
  margin-right: 10px;
}
.dead_line {
  padding: 10px 18px;
  font-size: 13px;
  color: red !important;
}
.timeline-event-time {
  color: black !important;
}
